import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  tab:'AXIS',
  newBranch: [],
  bankData :[],
  postbankStatement:[],
};

const slice = createSlice({
  name: 'banking',
  initialState,
  reducers: {
     setBankData(state, action) {
      state.bankData =action.payload;
    },
    setTab(state, action) {
      state.tab =action.payload;
    },
    setPostbankStatement(state, action) {
      state.postbankStatement =action.payload;
    },

  },
});

export default slice.reducer;

export const { setBankData, setTab, setPostbankStatement} = slice.actions;


export const getBankStatementAll = (a) => async (dispatch) => {
  try {
    await axios.get(`/get_bankStatementAll?bank_Name=${a}`).then((response) => dispatch(setBankData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPostbankStatement = (id, ledId, type) => async (dispatch) => {
  try {
    await axios.post(`post_bankStatement?id=${id}&matched_Ledgerid=${ledId}&type=${type}`).then((response) => dispatch(setPostbankStatement(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
