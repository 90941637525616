import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  newInvoice: [],
  terms: [],
  show: false,
  invoiceNo: null,
  billNo: null,
  exportNo: null,
  deliveryNo: null,
  creditNo: null,
  salesOrderNo: null,
  performaNo: null,
  estimationNo: null,
  inventoryName: [],
  debitNo: null,
  // sales terms and condition
  termsCondition: null,
  billTerms: null,
  exportTerms: null,
  deliveryTerms: null,
  creditTerms: null,
  salesOrderTerms: null,
  performaTerms: null,
  estimationTerms: null,
  debitTerms: null,
  // sales form Data
  invoiceData: null,
  billData: null,
  exportData: null,
  salesOrderData: null,
  deliveryData: null,
  creditData: null,
  performaData: null,
  estimationData: null,
  debitData: null,
  stockData: null,
  otherIncomeData: null,
  commercialData: null,
  allInvoice: [],
  // sales Edit
  commercialEdit: null,
  invoiceEdit: null,
  billEdit: null,
  exportEdit: null,
  deliveryEdit: null,
  creditEdit: null,
  salesOrderEdit: null,
  performaEdit: null,
  estimationEdit: null,
  debitEdit: null,
  stockEdit: null,
  otherIncomeEdit: null,
  invoiceSummary: null,
  isLoading: true,
};

const slice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setCommercialdata(state, action) {
      state.commercialData = action.payload;
    },

    setTerms(state, action) {
      state.terms = action.payload;
    },
    setDebitTerms(state, action) {
      state.debitTerms = action.payload;
    },
    setDebitNo(state, action) {
      state.debitNo = action.payload;
    },
    setDebitdata(state, action) {
      state.debitData = action.payload;
    },
    setOtherIncomeEdit(state, action) {
      state.otherIncomeEdit = action.payload;
    },
    setDebitEdit(state, action) {
      state.debitEdit = action.payload;
    },
    setEstimationEdit(state, action) {
      state.estimationEdit = action.payload;
    },
    setPerformaEdit(state, action) {
      state.performaEdit = action.payload;
    },
    setSalesOrderEdit(state, action) {
      state.salesOrderEdit = action.payload;
    },
    setCreditEdit(state, action) {
      state.creditEdit = action.payload;
    },
    setDeliveryEdit(state, action) {
      state.deliveryEdit = action.payload;
    },
    setExportEdit(state, action) {
      state.exportEdit = action.payload;
    },
    setBillEdit(state, action) {
      state.billEdit = action.payload;
    },
    setInvoiceEdit(state, action) {
      state.invoiceEdit = action.payload;
    },
    setCommercialEdit(state, action) {
      state.commercialEdit = action.payload;
    },
    setStockEdit(state, action) {
      state.stockEdit = action.payload;
    },
    setInvoice(state, action) {
      // state.newInvoice = [...state.newInvoice, action.payload];
      const newInvoice = action.payload;
      state.newInvoice = newInvoice;
    },
    setShow(state, action) {
      state.show = action.payload;
    },
    setInvoiceNo(state, action) {
      state.invoiceNo = action.payload;
    },
    setBillNo(state, action) {
      state.billNo = action.payload;
    },
    setExportNo(state, action) {
      state.exportNo = action.payload;
    },
    setDeliveryNo(state, action) {
      state.deliveryNo = action.payload;
    },
    setCreditNo(state, action) {
      state.creditNo = action.payload;
    },
    setSalesOrderNo(state, action) {
      state.salesOrderNo = action.payload;
    },
    setPerformaNo(state, action) {
      state.performaNo = action.payload;
    },
    setEstimationNo(state, action) {
      state.estimationNo = action.payload;
    },
    setInventoryName(state, action) {
      state.inventoryName = action.payload;
    },
    setInvoicedata(state, action) {
      const invoiceData = action.payload;
      state.invoiceData = invoiceData;
    },
    setStockdata(state, action) {
      state.stockData = action.payload;
    },
    setBilldata(state, action) {
      state.billData = action.payload;
    },
    setExportdata(state, action) {
      state.exportData = action.payload;
    },
    setDeliverydata(state, action) {
      state.deliveryData = action.payload;
    },
    setCreditdata(state, action) {
      state.creditData = action.payload;
    },
    setSalesOrderdata(state, action) {
      state.salesOrderData = action.payload;
    },
    setPerformadata(state, action) {
      state.performaData = action.payload;
    },
    setEstimationdata(state, action) {
      state.estimationData = action.payload;
    },
    setOtherIncomeData(state, action) {
      state.otherIncomeData = action.payload;
    },
    setTermsCondition(state, action) {
      state.termsCondition = action.payload;
    },
    setBillTerms(state, action) {
      state.billTerms = action.payload;
    },
    setExportTerms(state, action) {
      state.exportTerms = action.payload;
    },
    setDeliveryTerms(state, action) {
      state.deliveryTerms = action.payload;
    },
    setCreditTerms(state, action) {
      state.deliveryTerms = action.payload;
    },
    setSalesOrderTerms(state, action) {
      state.salesOrderTerms = action.payload;
    },
    setPerformaTerms(state, action) {
      state.performaTerms = action.payload;
    },
    setEstimationTerms(state, action) {
      state.estimationTerms = action.payload;
    },
    setAllInvoicedata(state, action) {
      state.allInvoice = action.payload;
    },
    setInvoiceSummary(state, action) {
      state.invoiceSummary = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setInvoice,
  setShow,
  setInventoryName,
  setInvoicedata,
  setBilldata,
  setExportdata,
  setDeliverydata,
  setCreditdata,
  setSalesOrderdata,
  setPerformadata,
  setEstimationdata,
  setDebitdata,
  setStockdata,
  setOtherIncomeData,
  setAllInvoicedata,
  setCommercialdata,
  // All Sales Terms And Condition
  setTermsCondition,
  setBillTerms,
  setExportTerms,
  setDeliveryTerms,
  setCreditTerms,
  setSalesOrderTerms,
  setPerformaTerms,
  setEstimationTerms,
  setDebitTerms,
  // All Sales No
  setInvoiceNo,
  setBillNo,
  setExportNo,
  setDeliveryNo,
  setCreditNo,
  setSalesOrderNo,
  setPerformaNo,
  setEstimationNo,
  setDebitNo,
  // sales edit
  setCommercialEdit,
  setInvoiceEdit,
  setBillEdit,
  setExportEdit,
  setDeliveryEdit,
  setCreditEdit,
  setSalesOrderEdit,
  setPerformaEdit,
  setEstimationEdit,
  setDebitEdit,
  setTerms,
  setStockEdit,
  setOtherIncomeEdit,

  setInvoiceSummary,
  setIsLoading,
} = slice.actions;

export const getInvoiceSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/summary/INV?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBillSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/summary/BOS?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getExportSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/summary/EXT?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDeliverySummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/summary/DCL?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getCreditSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/summary/CNT?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDebitSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/summary/DBT?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSalesOrderSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/summary/SOD?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPerformaSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/summary/PIV?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getEstimationSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/summary/ETB?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getOtherIncomeSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/summary/OTH?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getCommercialSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/summary/COM?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setInvoiceSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getOtherIncomeEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/sales/otherIncome?ID=${id}`).then((response) => dispatch(setOtherIncomeEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getOtherIncomeAll = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/otherIncomeAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setOtherIncomeData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getStock = (id) => async (dispatch) => {
  try {
    await axios.get(`/sales/stock-jurnal?ID=${id}`).then((response) => dispatch(setStockEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getStockAll = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/stock-jurnalAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setStockdata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTerms = (categoryType, subType) => async (dispatch) => {
  try {
    await axios
      .get(`/getCategory?categoryType=${categoryType}&subType=${subType}`)
      .then((response) => dispatch(setTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDebitNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/DBN').then((response) => dispatch(setDebitNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDebit = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/debitAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setDebitdata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDebitTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/DBN').then((response) => dispatch(setDebitTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDebitEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/debit?ID=${id}`).then((response) => dispatch(setDebitEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getInvoiceEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/invoice?ID=${id}`).then((response) => dispatch(setInvoiceEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getBillEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/billofsupply?ID=${id}`).then((response) => dispatch(setBillEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getExportEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/export?ID=${id}`).then((response) => dispatch(setExportEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getCommercialEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/commercial?ID=${id}`).then((response) => dispatch(setCommercialEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getDeliveryEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/delivery?ID=${id}`).then((response) => dispatch(setDeliveryEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};
export const getCreditEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/credit?ID=${id}`).then((response) => dispatch(setCreditEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};
export const getSalesOrderEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/saleorder?ID=${id}`).then((response) => dispatch(setSalesOrderEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getPerformaEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/performainvoice?ID=${id}`).then((response) => dispatch(setPerformaEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getEstimationEdit = (id) => async (dispatch) => {
  if (id && typeof id === 'number') {
    try {
      await axios.get(`/sales/estimation?ID=${id}`).then((response) => dispatch(setEstimationEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
  }
  return true;
};

export const getTermsCondition = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/INV').then((response) => dispatch(setTermsCondition(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getBillTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/BOS').then((response) => dispatch(setBillTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getExportTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/EXP').then((response) => dispatch(setExportTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getDeliveryTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/DCL').then((response) => dispatch(setDeliveryTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getCreditTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/CNT').then((response) => dispatch(setCreditTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getSalesOrderTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/SOD').then((response) => dispatch(setSalesOrderTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPerformaTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/PIV').then((response) => dispatch(setPerformaTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getEstimationTerms = () => async (dispatch) => {
  try {
    await axios.get('/sales/termscondition/ETB').then((response) => dispatch(setEstimationTerms(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSaleCustomerName = () => async (dispatch) => {
  try {
    await axios.get('/sales/ledger').then((response) => dispatch(setInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoiceNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/INV').then((response) => dispatch(setInvoiceNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getBillNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/BOS').then((response) => dispatch(setBillNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getExportNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/EXP').then((response) => dispatch(setExportNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getCommercialNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/COM').then((response) => dispatch(setExportNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getDeliveryNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/DCL').then((response) => dispatch(setDeliveryNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getCreditNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/CNT').then((response) => dispatch(setCreditNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getSalesOrderNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/SOD').then((response) => dispatch(setSalesOrderNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPerformaNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/PIV').then((response) => dispatch(setPerformaNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getEstimationNo = () => async (dispatch) => {
  try {
    await axios.get('/sales/invoiceNumber/ETB').then((response) => dispatch(setEstimationNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInventoryName = (date) => async (dispatch) => {
  try {
    await axios.get(`/sales/inventory?invDate=${date}`).then((response) => dispatch(setInventoryName(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoice = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/invoiceAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => {
        dispatch(setInvoicedata(response.data));
        dispatch(setIsLoading(false));
      });
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getCommercialAll = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/commercialAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setCommercialdata(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getBill = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/billofsupplyAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setBilldata(response.data)));
      dispatch(setIsLoading(false));

  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getExport = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/exportAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setExportdata(response.data)));
      dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getDelivery = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/deliveryAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setDeliverydata(response.data)));
      dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getCredit = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/creditAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setCreditdata(response.data)));
      dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getSalesOrder = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/saleorderAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setSalesOrderdata(response.data)));
      dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getPerformaInvoice = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/performainvoiceAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setPerformadata(response.data)));
      dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getEstimation = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/sales/etimationAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setEstimationdata(response.data)));
      dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getAllInvoice = () => async (dispatch) => {
  try {
    await axios.get('/sales/get_inv_list').then((response) => dispatch(setAllInvoicedata(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
