// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'About us',
    icon: <Iconify icon="mdi:about" {...ICON_SIZE} />,
    path: '/about-us',
  },
  {
    title: 'Features',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },

  {
    title: 'Pricing Plans',
    icon: <Iconify icon="eos-icons:subscriptions-created" {...ICON_SIZE} />,
    path: '/pricing-plans',
  },

  {
    title: 'Contact Us',
    icon: <Iconify icon="ic:round-contact-mail" {...ICON_SIZE} />,
    path: '/contact-us',
  },
  // {
  //   title: 'Report an Issue',
  //   icon: <Iconify icon="ic:round-contact-mail" {...ICON_SIZE} />,
  //   path: '/report-an-issue',
  // },
];

export default menuConfig;
