import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  employeeSalaryEdit: {},
  employeeSalary: [],
  EmployeeDetails:[],
  salaryAll:[],
  salarySummary:[],
  tab:'employee',
  isLoading: true,

};

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployeeSalary(state, action) {
      state.employeeSalary = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setEmployeeSalaryEdit(state, action) {
      state.employeeSalaryEdit = action.payload;
    },
    setEmployeeDetails(state, action) {
      state.EmployeeDetails = action.payload;
    },
    setSalaryAll(state, action) {
      state.salaryAll = action.payload;
    },
    setPdf(state, action) {
      state.pdf = action.payload;
    },
    setIsloading(state, action) {
      state.isLoading = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setSalarySummary(state, action) {
      state.salarySummary = action.payload;
    }
  },
});

export default slice.reducer;

export const { 
  setEmployeeSalaryEdit,
   setEmployeeSalary,
   setEmployeeDetails,
   setSalaryAll,
    setPdf,
    setIsloading,
    setTab,
    setSalarySummary,
    setIsLoading
   } = slice.actions;

export const getEmployeeSalary = (date) => async (dispatch) => {
  try {
    await axios.get(`/employee/salary?end_date=${date}`).then((response) => dispatch(setEmployeeSalary(response.data)));
    dispatch(setIsloading(false));
  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getEmployeeSalaryEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/employee/details?ID=${id}`).then((response) => dispatch(setEmployeeSalaryEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getEmployeeDetails = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios.get(`/employee/detailsAll?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`).then((response) => dispatch(setEmployeeDetails(response.data)));
    dispatch(setIsLoading(false));

  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getSalaryAll = (sd,ed,n,r,s,sts) => async (dispatch) => {
  try {
    await axios.get(`/employee/SalaryAll?start=${sd}&end=${ed}&pagination=${n}&records=${r}&search=${s}&status=${sts}`).then((response) => dispatch(setSalaryAll(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSalarySummary = (sd,ed) => async (dispatch) => {
  try{
await axios.get(`/employee/SalarySummary?start=${sd}&end=${ed}`).then((response) => dispatch(setSalarySummary(response.data)));
  }catch (error) {
 return console.error(error.message)
  }
}
